import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      component: () => import('@/pages/HomePage'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/terminos-condiciones',
      component: () => import('@/pages/TermsPage'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/aviso-privacidad',
      component: () => import('@/pages/PrivPage'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '*',
      component: () => import('@/pages/HomePage'),
      meta: {
        requiresAuth: false,
      },
    },
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router